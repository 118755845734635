import React from 'react';
import { Layout, Row, Col } from 'antd';
import { PhoneOutlined, HomeOutlined, MailOutlined } from '@ant-design/icons';
import { FaHome, FaPhone, FaEnvelope, FaYoutube, FaGithub, FaFacebook, FaLinkedin, FaTwitter, FaInstagram, FaDiscord } from 'react-icons/fa';
import { RiOpenSourceFill } from 'react-icons/ri';
import Configuration from '../../components/Configuration';
import Dictionary from '../../components/Dictionary';

import './index.less';

const { Footer } = Layout;

function BaseFooter() {
  return (
    <Footer>
      <Row className="ant-layout-footer__wrapper" align="middle" gutter={[0, 10]}>
        <Col xs={24} lg={8}>
          <div className="logo" data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease-out-cubic">
            <img alt="logo" src="/images/logo.png" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 15, offset: 1 }} className="footer__content">
          <Row>
            <Col>
              <h4><Dictionary entry="footer-follow-us-title" /></h4>
              <Dictionary entry="footer-follow-us-content" />
            </Col>
          </Row>
          <Row className="footer__contacts">
            <Col span={24}>
              <p><MailOutlined /> <Configuration parameter="footer-email"/></p>
            </Col>
          </Row>
          <p className="social-links">
            <a href="https://github.com/perifacode/" className="share-icons">
              <FaGithub />
            </a>
            <a href="https://www.youtube.com/c/perifaCode" className="share-icons">
              <FaYoutube />
            </a>
            <a href="https://twitter.com/perifacode" className="share-icons">
              <FaTwitter />
            </a>
            <a href="https://www.linkedin.com/company/perifacode/" className="share-icons">
              <FaLinkedin />
            </a>
            <a href="https://www.instagram.com/perifacode/" className="share-icons">
              <FaInstagram />
            </a>
            <a href="https://www.facebook.com/perifaCode" className="share-icons">
              <FaFacebook />
            </a>
          </p>
        </Col>
      </Row>
    </Footer>
  );
}

export default BaseFooter;